<template>
    <section class="invoice-add-wrapper">
        <!-- Alert: choose shopId first -->
        <div v-if="!hasShopId">
            <b-alert variant="warning" :show="blankOrderData.order.shopId === null">
                <!--        <h4 class="alert-heading">-->
                <!--        </h4>-->
                <div class="alert-body">You can continue after choosing shop</div>
            </b-alert>
            <div class="fade show">
                <span class="title">Select Shop</span>
                <v-select
                    id="shopSelectComponent"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="shopOptions"
                    label="name"
                    :clearable="false"
                    class="mb-2 item-selector-title"
                    placeholder="Start typing..."
                    @focusout="window.dispatchEvent(new Event('resize'))"
                    @search="searchShops"
                    @input="val => updateShopId(val)"
                >
                    <!--          v-model="blankOrderData.order.shopId"-->
                    <!--@input="val => updateItemForm(index, val)"-->
                    <template #option="{ name }">
                        <span>
                            {{ name }}
                        </span>
                    </template>
                </v-select>
            </div>
        </div>

        <!-- Alert Choose Customer Then -->
        <div v-else-if="!hasCustomerOriginId">
            <b-alert variant="warning" :show="blankOrderData.order.customerOriginId === null">
                <!--        <h4 class="alert-heading">-->
                <!--        </h4>-->
                <div class="alert-body">Please also choose customer</div>
            </b-alert>
            <div class="fade show">
                <span class="title">Select Customer</span>
                <v-select
                    id="customerSelectComponent"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="customerOptions"
                    label="firstName"
                    class="mb-2 item-selector-title"
                    placeholder="Search customer..."
                    @search="searchCustomers"
                    @input="val => updateCustomerId(val)"
                >
                    <!-- :clearable="false" -->
                    <!--          v-model="blankOrderData.order.shopId"-->
                    <!--@input="val => updateItemForm(index, val)"-->
                    <template #option="{ firstName, lastName, username, isPayingCustomer, email }">
                        <p class="text-wrap font-weight-bolder">
                            {{ firstName }} {{ lastName }} -
                            <span class="text-wrap font-weight-bold">{{
                                resolveCustomerIsPayingStatus(isPayingCustomer)
                            }}</span>
                        </p>
                        <span class="text-wrap font-weight-bolder">
                            {{ username }} -
                            <span class="text-wrap font-weight-bold">
                                {{ email }}
                            </span>
                        </span>
                    </template>
                </v-select>
            </div>
        </div>

        <b-row v-else class="invoice-add">
            <!-- Col: Left (Invoice Container) -->
            <b-col cols="12" xl="9" md="8">
                <b-form @submit.prevent>
                    <b-card no-body class="invoice-preview-card">
                        <!-- Header -->
                        <b-card-body class="invoice-padding">
                            <h4 class="mb-2">Billing:</h4>
                            <div class="d-lg-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                                <b-col class="pl-0">
                                    <!-- Billing Left Side Start -->
                                    <div class="mt-1">
                                        <div class="mt-1">
                                            <p class="font-weight-bolder text-wrap">First Name</p>
                                            <!-- <p>{{ orderData.billing.first_name }}</p> -->
                                            <b-form-input v-model="orderData.billing.first_name" class="mt-1" />
                                        </div>
                                        <div class="mt-1">
                                            <p class="font-weight-bolder text-wrap">Last Name</p>
                                            <!-- <p>{{ orderData.billing.last_name }}</p> -->
                                            <b-form-input v-model="orderData.billing.last_name" class="mt-1" />
                                        </div>
                                        <div class="mt-1">
                                            <p class="font-weight-bolder text-wrap">Company</p>
                                            <!-- <p>{{ orderData.billing.company }}</p> -->
                                            <b-form-input v-model="orderData.billing.company" class="mt-1" />
                                        </div>
                                        <div class="mt-1">
                                            <p class="font-weight-bolder text-wrap">Address Line 1</p>
                                            <!-- <p>{{ orderData.billing.address_1 }}</p> -->
                                            <b-form-input v-model="orderData.billing.address_1" class="mt-1" />
                                        </div>
                                        <div class="mt-1">
                                            <p class="font-weight-bolder text-wrap">Address Line 2</p>
                                            <!-- <p>{{ orderData.billing.address_2 }}</p> -->
                                            <b-form-input v-model="orderData.billing.address_2" class="mt-1" />
                                        </div>
                                        <div class="mt-1">
                                            <p class="font-weight-bolder text-wrap">City</p>
                                            <!-- <p>{{ orderData.billing.city }}</p> -->
                                            <b-form-input v-model="orderData.billing.city" class="mt-1" />
                                        </div>
                                    </div>
                                    <!-- Billing Left Side End -->
                                </b-col>
                                <b-col class="pl-0 pl-lg-1">
                                    <!-- Billing Right Side Start -->
                                    <div class="mt-1">
                                        <div class="mt-1">
                                            <p class="font-weight-bolder text-wrap">State</p>
                                            <!-- <p>{{ orderData.billing.state }}</p> -->
                                            <b-form-input v-model="orderData.billing.state" class="mt-1" />
                                        </div>
                                        <div class="mt-1">
                                            <p class="font-weight-bolder text-wrap">Postcode</p>
                                            <!-- <p>{{ orderData.billing.postcode }}</p> -->
                                            <b-form-input v-model="orderData.billing.postcode" class="mt-1" />
                                        </div>
                                        <div class="mt-1">
                                            <p class="font-weight-bolder text-wrap">Country</p>
                                            <!-- <p>{{ orderData.billing.country }}</p> -->
                                            <b-form-input v-model="orderData.billing.country" class="mt-1" />
                                        </div>
                                        <div class="mt-1">
                                            <p class="font-weight-bolder text-wrap">Email</p>
                                            <!-- <p>{{ orderData.billing.email }}</p> -->
                                            <b-form-input v-model="orderData.billing.email" class="mt-1" />
                                        </div>
                                        <div class="mt-1">
                                            <p class="font-weight-bolder text-wrap">Phone</p>
                                            <!-- <p>{{ orderData.billing.phone }}</p> -->
                                            <b-form-input v-model="orderData.billing.phone" class="mt-1" />
                                        </div>
                                    </div>
                                    <!-- Billing Right Side End -->
                                </b-col>
                            </div>
                        </b-card-body>

                        <!-- Spacer -->
                        <hr class="invoice-spacing" />

                        <!-- Invoice Client & Payment Details -->
                        <b-card-body class="invoice-padding">
                            <h4 class="mb-2">Shipping:</h4>
                            <b-button
                                class="mt-1"
                                variant="info"
                                @click="copyDetails(orderData.billing, orderData.shipping)"
                                >Copy from Billing</b-button
                            >
                            <div class="d-lg-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                                <!-- Shipping Left Side Start -->
                                <b-col class="pl-0">
                                    <div class="mt-1">
                                        <div class="mt-1">
                                            <p class="font-weight-bolder text-wrap">First Name</p>
                                            <!-- <p>{{ orderData.shipping.first_name }}</p> -->
                                            <b-form-input v-model="orderData.shipping.first_name" class="mt-1" />
                                        </div>
                                        <div class="mt-1">
                                            <p class="font-weight-bolder text-wrap">Last Name</p>
                                            <!-- <p>{{ orderData.shipping.last_name }}</p> -->
                                            <b-form-input v-model="orderData.shipping.last_name" class="mt-1" />
                                        </div>
                                        <div class="mt-1">
                                            <p class="font-weight-bolder text-wrap">Company</p>
                                            <!-- <p>{{ orderData.shipping.company }}</p> -->
                                            <b-form-input v-model="orderData.shipping.company" class="mt-1" />
                                        </div>
                                        <div class="mt-1">
                                            <p class="font-weight-bolder text-wrap">Address Line 1</p>
                                            <!-- <p>{{ orderData.shipping.address_1 }}</p> -->
                                            <b-form-input v-model="orderData.shipping.address_1" class="mt-1" />
                                        </div>
                                        <div class="mt-1">
                                            <p class="font-weight-bolder text-wrap">Address Line 2</p>
                                            <!-- <p>{{ orderData.shipping.address_2 }}</p> -->
                                            <b-form-input v-model="orderData.shipping.address_2" class="mt-1" />
                                        </div>
                                    </div>
                                </b-col>
                                <!-- Shipping Left Side End -->
                                <b-col class="pl-0 pl-lg-1">
                                    <!-- Shipping Right Side Start -->

                                    <div class="mt-1">
                                        <p class="font-weight-bolder text-wrap">City</p>
                                        <!-- <p>{{ orderData.shipping.city }}</p> -->
                                        <b-form-input v-model="orderData.shipping.city" class="mt-1" />
                                    </div>
                                    <div class="mt-1">
                                        <div class="mt-1">
                                            <p class="font-weight-bolder text-wrap">State</p>
                                            <!-- <p>{{ orderData.shipping.state }}</p> -->
                                            <b-form-input v-model="orderData.shipping.state" class="mt-1" />
                                        </div>
                                        <div class="mt-1">
                                            <p class="font-weight-bolder text-wrap">Postcode</p>
                                            <!-- <p>{{ orderData.shipping.postcode }}</p> -->
                                            <b-form-input v-model="orderData.shipping.postcode" class="mt-1" />
                                        </div>
                                        <div class="mt-1">
                                            <p class="font-weight-bolder text-wrap">Country</p>
                                            <!-- <p>{{ orderData.shipping.country }}</p> -->
                                            <b-form-input v-model="orderData.shipping.country" class="mt-1" />
                                        </div>
                                        <div class="mt-1">
                                            <p class="font-weight-bolder text-wrap">Phone</p>
                                            <!-- <p>{{ orderData.shipping.phone }}</p> -->
                                            <b-form-input v-model="orderData.shipping.phone" class="mt-1" />
                                        </div>
                                    </div>
                                </b-col>

                                <!-- Shipping Right Side End -->
                            </div>
                            <!-- Col: Payment Details -->
                            <div>
                                <h4 class="mb-1">Payment Details:</h4>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td class="pr-1">Total Due:</td>
                                            <td class="font-weight-bolder">
                                                {{
                                                    calculateOrderTotal(
                                                        blankOrderData.order.lineItems,
                                                        blankOrderData.order.fees,
                                                    ) | formatCurrency
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="pr-1">Status:</td>
                                            <td>{{ resolveOrderStatus(blankOrderData.order.status) }}</td>
                                        </tr>
                                        <tr>
                                            <td class="pr-1">Payment Method:</td>
                                            <td>{{ blankOrderData.order.paymentMethodTitle }}</td>
                                        </tr>
                                        <tr v-if="blankOrderData.order.customerIp">
                                            <td class="pr-1">Customer IP:</td>
                                            <td>{{ blankOrderData.order.customerIp }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </b-card-body>

                        <!-- Items & Fees Section -->
                        <b-card-body class="invoice-padding form-item-section">
                            <div ref="form" class="repeater-form" :style="{ height: trHeight }">
                                <!-- Items -->
                                <b-row
                                    v-for="(item, index) in blankOrderData.order.lineItems"
                                    :key="index"
                                    ref="row"
                                    class="pb-2"
                                >
                                    <!-- Item Form -->
                                    <!-- ? This will be in loop => So consider below markup for single item -->
                                    <b-col cols="12">
                                        <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                                        <div class="d-none d-lg-flex">
                                            <b-row class="flex-grow-1 px-1">
                                                <!-- Single Item Form Headers -->
                                                <b-col cols="12" lg="5"> Name </b-col>
                                                <b-col cols="12" lg="3"> Price </b-col>
                                                <b-col cols="12" lg="2"> Quantity </b-col>
                                                <b-col cols="12" lg="2"> Total </b-col>
                                            </b-row>
                                            <div class="form-item-action-col" />
                                        </div>

                                        <!-- Form Input Fields OR content inside bordered area  -->
                                        <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                                        <div class="d-flex border rounded">
                                            <b-row class="flex-grow-1 p-2">
                                                <!-- Single Item Form Headers -->
                                                <b-col cols="12" lg="5">
                                                    <label class="d-inline d-lg-none">Name</label>
                                                    <!--TODO itemsOptions YERINE QUERY ATTIRILACAK OPTIONS ICIN-->
                                                    <v-select
                                                        v-model="item.name"
                                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                        :options="productOptions"
                                                        label="name"
                                                        :clearable="false"
                                                        class="mb-2 item-selector-title"
                                                        placeholder="Start typing..."
                                                        @input="val => updateItemForm(index, val)"
                                                        @focusout="window.dispatchEvent(new Event('resize'))"
                                                        @search="searchProducts"
                                                    >
                                                        <template #option="{ name, price }">
                                                            <p class="p-0 m-0 text-wrap">
                                                                {{ name }}
                                                            </p>
                                                            <p class="text-wrap font-weight-bolder p-0 mt-50 mb-50">
                                                                {{ price | formatCurrency }}
                                                            </p>
                                                        </template>
                                                    </v-select>
                                                </b-col>
                                                <b-col cols="12" lg="3">
                                                    <label class="d-inline d-lg-none">Price</label>
                                                    <b-form-input
                                                        v-model="item.price"
                                                        disabled
                                                        type="number"
                                                        class="mb-2"
                                                        @input="window.dispatchEvent(new Event('resize'))"
                                                    />
                                                </b-col>
                                                <b-col cols="12" lg="2">
                                                    <label class="d-inline d-lg-none">Quantity</label>
                                                    <b-form-input
                                                        v-model="item.quantity"
                                                        v-format-currency
                                                        type="number"
                                                        :state="item.quantity > 0"
                                                        class="mb-2"
                                                    />
                                                    <!--                            @input="window.dispatchEvent(new Event('resize'))"-->
                                                </b-col>
                                                <b-col cols="12" lg="2">
                                                    <label class="d-inline d-lg-none">Total</label>
                                                    <p class="mb-1">
                                                        {{ calculateItemTotal(item) | formatCurrency }}
                                                    </p>
                                                </b-col>
                                            </b-row>
                                            <div
                                                class="d-flex flex-column justify-content-between border-left py-50 px-25"
                                            >
                                                <feather-icon
                                                    size="16"
                                                    icon="XIcon"
                                                    class="cursor-pointer"
                                                    @click="removeItem(index)"
                                                />
                                            </div>
                                        </div>
                                    </b-col>
                                </b-row>
                                <!-- Fees -->
                                <b-row
                                    v-for="(fee, index) in blankOrderData.order.fees"
                                    :key="index + 'fee'"
                                    ref="row"
                                    class="pb-2"
                                >
                                    <!-- Item Form -->
                                    <!-- ? This will be in loop => So consider below markup for single item -->
                                    <b-col cols="12">
                                        <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                                        <div class="d-none d-lg-flex">
                                            <b-row class="flex-grow-1 px-1">
                                                <!-- Single Item Form Headers -->
                                                <b-col cols="12" lg="5"> Name </b-col>
                                                <b-col cols="12" lg="3"> Price </b-col>
                                            </b-row>
                                            <div class="form-item-action-col" />
                                        </div>

                                        <!-- Form Input Fields OR content inside bordered area  -->
                                        <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                                        <div class="d-flex border rounded">
                                            <b-row class="flex-grow-1 p-2">
                                                <!-- Single Item Form Headers -->
                                                <b-col cols="12" lg="5">
                                                    <label class="d-inline d-lg-none">Name</label>
                                                    <b-form-input
                                                        v-model="fee.name"
                                                        label="Name"
                                                        type="text"
                                                        class="mb-2"
                                                        placeholder="Fee name"
                                                    >
                                                        {{ fee.name }}
                                                    </b-form-input>
                                                </b-col>
                                                <b-col cols="12" lg="3">
                                                    <label class="d-inline d-lg-none">Price</label>
                                                    <b-form-input v-model="fee.total" type="number" class="mb-2" />
                                                </b-col>
                                            </b-row>
                                            <div
                                                class="d-flex flex-column justify-content-between border-left py-50 px-25"
                                            >
                                                <feather-icon
                                                    size="16"
                                                    icon="XIcon"
                                                    class="cursor-pointer"
                                                    @click="removeFee(index)"
                                                />
                                            </div>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                            <div class="d-flex justify-content-between">
                                <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    size="sm"
                                    variant="primary"
                                    @click="addNewItemInItemForm"
                                >
                                    Add Item
                                </b-button>
                                <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    size="sm"
                                    class="ml-3"
                                    variant="primary"
                                    @click="addNewFeeInFeeForm"
                                >
                                    Add Fees
                                </b-button>
                            </div>
                        </b-card-body>

                        <!-- Invoice Description: Total -->
                        <b-card-body class="invoice-padding pb-1 d-flex justify-content-end">
                            <div class="w-100 text-right">
                                <div
                                    v-for="(fee, index) in blankOrderData.order.fees"
                                    :key="'fee_' + index"
                                    class="invoice-total-item"
                                >
                                    <p class="font-weight-bolder">{{ fee.name ? fee.name : "Untitled Fee" }}</p>
                                    <p>
                                        {{ (fee.total ? fee.total : 0) | formatCurrency }}
                                    </p>
                                </div>
                                <hr class="my-50" />
                                <div class="invoice-total-item">
                                    <p class="invoice-total-title font-weight-bold">Total:</p>
                                    <p v-if="blankOrderData.order.fees" class="invoice-total-amount">
                                        {{
                                            calculateOrderTotal(
                                                blankOrderData.order.lineItems,
                                                blankOrderData.order.fees,
                                            ) | formatCurrency
                                        }}
                                    </p>
                                </div>
                            </div>
                        </b-card-body>
                    </b-card>
                </b-form>
            </b-col>

            <!-- Right Col: Card -->
            <b-col cols="12" md="4" xl="3" class="invoice-actions">
                <b-card>
                    <!-- Order Status Updater -->
                    <div class="d-flex justify-content-between align-items-center">
                        <h5>Order Status:</h5>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                        <!-- <b-form-select v-model="blankOrderData.order.status" :options="orderStatusOptions" /> -->
                        <v-select
                            v-model="blankOrderData.order.status"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="orderStatusOptions"
                            :reduce="val => val.value"
                            class="w-100"
                            :clearable="false"
                            placeholder="Select Status"
                        >
                            <template #selected-option="{ label }">
                                <span class="text-wrap overflow-hidden">
                                    {{ label }}
                                </span>
                            </template>
                        </v-select>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mt-2">
                        <h5>Payment Method:</h5>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                        <!-- <b-form-select v-model="blankOrderData.order.paymentMethod" :options="orderPaymentOptions">
                            {{ blankOrderData.order.paymentMethod }}
                        </b-form-select> -->
                        <v-select
                            v-model="blankOrderData.order.paymentMethod"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="orderPaymentOptions"
                            :reduce="val => val.value"
                            class="w-100"
                            :clearable="false"
                            placeholder="Select Payment Method"
                        >
                            <template #selected-option="{ label }">
                                <span class="text-wrap overflow-hidden">
                                    {{ label }}
                                </span>
                            </template>
                        </v-select>
                    </div>
                </b-card>
                <!-- Action Buttons -->
                <b-card>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        v-b-toggle.sidebar-send-invoice
                        variant="success"
                        block
                        @click="createOrder"
                    >
                        Create Order
                    </b-button>
                </b-card>
            </b-col>
        </b-row>
    </section>
</template>

<script>
import { nextTick, onUnmounted, ref } from "@vue/composition-api";
import emptyOrderData from "./emptyOrderData";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import { BAlert, BButton, BCard, BCardBody, BCol, BForm, BFormInput, BRow, VBToggle } from "bootstrap-vue";
import vSelect from "vue-select";
import { resolveItemNames } from "@/utils/naming.utils";
import { calculateItemTotal, calculateOrderTotal } from "@/utils/total.utils";
import store from "@/store";
import orderStoreModule from "../orderStoreModule";
import { debounce } from "debounce";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import router from "@/router";

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardBody,
        BButton,
        BForm,
        BFormInput,
        // BFormSelect,
        BAlert,
        vSelect,
    },
    directives: {
        Ripple,
        "b-toggle": VBToggle,
    },
    mixins: [heightTransition],

    // Reset Tr Height if data changes
    watch: {
        // eslint-disable-next-line func-names
        "blankOrderData.order.lineItems": function () {
            this.initTrHeight();
            window.dispatchEvent(new Event("resize"));
        },
        "blankOrderData.order.fees": function () {
            window.dispatchEvent(new Event("resize"));
            this.initTrHeight();
        },
        "blankOrderData.order.paymentMethod": function () {
            this.blankOrderData.order.paymentMethodTitle = this.orderPaymentOptions.find(
                option => option.value === this.blankOrderData.order.paymentMethod,
            ).label;
        },
    },
    mounted() {
        this.initTrHeight();
    },
    created() {
        window.addEventListener("resize", this.initTrHeight);
    },
    destroyed() {
        window.removeEventListener("resize", this.initTrHeight);
    },
    methods: {
        addNewItemInItemForm() {
            this.$refs.form.style.overflow = "hidden";
            this.blankOrderData.order.lineItems.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)));
            this.$nextTick(() => {
                this.trAddHeight(this.$refs.row[0].offsetHeight);
                setTimeout(() => {
                    this.$refs.form.style.overflow = null;
                }, 350);
            });
            window.dispatchEvent(new Event("resize"));
        },
        addNewFeeInFeeForm() {
            this.$refs.form.style.overflow = "hidden";
            this.blankOrderData.order.fees.push(JSON.parse(JSON.stringify(this.feeFormBlankItem)));
            this.$nextTick(() => {
                this.trAddHeight(this.$refs.row[0].offsetHeight);
                setTimeout(() => {
                    this.$refs.form.style.overflow = null;
                }, 350);
            });
            window.dispatchEvent(new Event("resize"));
        },
        removeItem(index) {
            // this.newblankOrderData.splice(index, 1);
            this.blankOrderData.order.lineItems[index].quantity = 0;
            window.dispatchEvent(new Event("resize"));
            this.trTrimHeight(this.$refs.row[0].offsetHeight);

            if (!this.blankOrderData[index].name) {
                this.blankOrderData.splice(index, 1);
                window.dispatchEvent(new Event("resize"));
            }
        },
        removeFee(index) {
            this.blankOrderData.order.fees[index].total = 0;
            // this.blankOrderData.fees.splice(index, 1);
            window.dispatchEvent(new Event("resize"));
            this.trTrimHeight(this.$refs.row[0].offsetHeight);
        },
        initTrHeight() {
            this.trSetHeight(null);
            this.$nextTick(() => {
                this.trSetHeight(this.$refs.form ? this.$refs.form.scrollHeight : 0);
            });
        },
        resolveOrderStatus(status) {
            if (status === "on-hold") {
                return "On Hold";
            }

            if (status === "processing") {
                return "Processing";
            }

            if (status === "delivered") {
                return "Delivered";
            }

            if (status === "completed") {
                return "Completed";
            }

            if (status === "shipped") {
                return "Shipped";
            }

            if (status === "partial-shipped") {
                return "Partial Shipped";
            }
        },
        resolvePaymentName(payment, payments) {
            payments.forEach(item => {
                if (item.originId === payment) {
                    return item.title;
                }
            });
        },
    },
    setup() {
        const INVOICE_APP_STORE_MODULE_NAME = "ecommerce-order";

        // Register module
        if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
            store.registerModule(INVOICE_APP_STORE_MODULE_NAME, orderStoreModule);
        }
        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
                store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
            }
        });

        const toast = useToast();
        const activeField = ref(null);
        const blankOrderData = ref(JSON.parse(JSON.stringify(emptyOrderData)));
        const productOptions = ref([]);
        const shopOptions = ref([]);
        const orderData = blankOrderData.value.order;
        const orderInitialCount = ref(0);
        const feeInitialCount = ref(0);
        const orderPaymentOptions = ref([]);
        const customerOptions = ref([]);
        const hasShopId = ref(false);
        const hasCustomerOriginId = ref(false);

        // blankOrderData.value.paymentMethods.forEach(paymentMethod => {
        //     orderPaymentOptions.value.push({
        //         value: paymentMethod.originId,
        //         label: paymentMethod.title,
        //     });
        // });

        const getProductsWithQuery = (query, loadingFunc) => {
            store
                .dispatch("ecommerce-order/fetchProducts", {
                    query: query,
                    shopId: blankOrderData.value.order.shopId,
                })
                .then(res => {
                    productOptions.value = res.products;
                    loadingFunc(false);
                })
                .catch(() => {
                    loadingFunc(false);
                });
        };

        const getShopsWithQuery = (query, loadingFunc) => {
            store
                .dispatch("ecommerce-order/fetchShops", {
                    query: query,
                })
                .then(res => {
                    shopOptions.value = res.shops;
                    loadingFunc(false);
                })
                .catch(() => {
                    loadingFunc(false);
                });
        };

        const getCustomersWithQuery = (query, loadingFunc) => {
            store
                .dispatch("ecommerce-order/fetchCustomers", {
                    query: query,
                    shopId: blankOrderData.value.order.shopId,
                })
                .then(res => {
                    customerOptions.value = res;
                })
                .finally(() => {
                    loadingFunc(false);
                });
        };

        const searchProducts = (search, loading) => {
            if (search.length) {
                loading(true);
                debounce(getProductsWithQuery(search, loading), 500);
            }
        };

        const searchShops = (search, loading) => {
            if (search.length) {
                loading(true);
                debounce(getShopsWithQuery(search, loading), 500);
            }
        };

        const searchCustomers = (search, loading) => {
            if (search.length) {
                loading(true);
                debounce(getCustomersWithQuery(search, loading), 500);
            }
        };

        // Template for new products
        // Gets populated using getProducts API
        const itemFormBlankItem = {
            id: null,
            name: null,
            price: null,
            quantity: null,
            originCreateDate: null,
            originUpdateDate: null,
            createDate: null,
            updateDate: null,
            image: null,
            shopId: null,
        };

        const feeFormBlankItem = {
            name: null,
            amount: null,
        };

        const updateShopId = shopIdObj => {
            hasShopId.value = true;
            blankOrderData.value.order.shopId = shopIdObj.id;
            getPaymentMethods(shopIdObj.id);
        };

        const updateCustomerId = customerIdObj => {
            if (!customerIdObj) {
                return;
            }
            if (customerIdObj.originId !== null) {
                hasCustomerOriginId.value = true;
                blankOrderData.value.order.customerId = customerIdObj.id;
                blankOrderData.value.order.billing.first_name = customerIdObj.firstName;
                blankOrderData.value.order.billing.last_name = customerIdObj.lastName;
                blankOrderData.value.order.billing.email = customerIdObj.email;
                blankOrderData.value.order.shipping.first_name = customerIdObj.firstName;
                blankOrderData.value.order.shipping.last_name = customerIdObj.lastName;
            } else {
                return new Error("CustomerID is invalid.");
            }
        };

        const updateItemForm = (index, val) => {
            const {
                id,
                name,
                price,
                quantity,
                originCreateDate,
                originUpdateDate,
                createDate,
                updateDate,
                image,
                shopId,
            } = val;

            blankOrderData.value.order.lineItems[index].id = id;
            blankOrderData.value.order.lineItems[index].name = name;
            blankOrderData.value.order.lineItems[index].price = price;
            blankOrderData.value.order.lineItems[index].quantity = quantity;
            blankOrderData.value.order.lineItems[index].originCreateDate = originCreateDate;
            blankOrderData.value.order.lineItems[index].originUpdateDate = originUpdateDate;
            blankOrderData.value.order.lineItems[index].createDate = createDate;
            blankOrderData.value.order.lineItems[index].updateDate = updateDate;
            blankOrderData.value.order.lineItems[index].image = image;
            blankOrderData.value.order.lineItems[index].shopId = shopId;
            window.dispatchEvent(new Event("resize"));
        };

        const updateFeeForm = (index, val) => {
            const { name, amount } = val;

            blankOrderData.value.order.fees[index].name = name;
            blankOrderData.value.order.fees[index].amount = amount;
            // resize window to scale ui
            window.dispatchEvent(new Event("resize"));
        };
        const triggerActiveField = string => {
            activeField.value = string;
            nextTick(() => {
                if (string !== null) {
                    document.getElementById(string).focus();
                }
            });
        };

        const outFocusActiveField = () => {
            activeField.value = null;
            document.activeElement.blur();
        };

        const setTotal = (items, fees) => {
            let total = 0;
            items.forEach(item => {
                total += item.price * item.quantity;
            });

            fees.forEach(fee => {
                total += fee.total;
            });

            if (items && fees) {
                return total;
            } else {
                return 0;
            }
        };

        const copyDetails = (fromObj, toObj) => {
            for (const key in fromObj) {
                if (toObj.hasOwnProperty(key)) {
                    toObj[key] = fromObj[key];
                }
            }
        };

        const getPaymentMethods = shopId => {
            store
                .dispatch("ecommerce-order/fetchPaymentMethods", {
                    shopId: shopId,
                })
                .then(paymentMethodsData => {
                    paymentMethodsData.forEach(paymentMethod => {
                        orderPaymentOptions.value.push({
                            value: paymentMethod.originId,
                            label: paymentMethod.title,
                        });
                    });
                })
                .catch(() => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "Error getting payment methods",
                            icon: "AlertTriangleIcon",
                            variant: "danger",
                        },
                    });
                });
        };

        const createOrder = async () => {
            if (blankOrderData.value.order.status === null) {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Order status is required",
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                    },
                });
                return;
            }
            if (blankOrderData.value.order.paymentMethod === null) {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Payment method is required",
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                    },
                });
                return;
            }
            blankOrderData.value.order.total = calculateOrderTotal(
                blankOrderData.value.order.lineItems,
                blankOrderData.value.order.fees,
            );
            store
                .dispatch("ecommerce-order/createOrder", blankOrderData.value.order)
                .then(res => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "Order Added!",
                            icon: "CheckCircleIcon",
                            variant: "success",
                        },
                    });
                    return res;
                })
                .then(res => {
                    // navigate to orders screen
                    if (res.status === 200) {
                        router.push({
                            name: "ecommerce-order-list",
                        });
                    }
                })
                .catch(() => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "An error occured while adding order!",
                            icon: "AlertTriangleIcon",
                            variant: "danger",
                        },
                    });
                });
        };

        const selectableModifier = (focused, refName) => {
            refName.value.disabled = !focused;
        };

        const orderStatusOptions = [
            {
                value: "on-hold",
                label: "On Hold",
            },
            {
                value: "processing",
                label: "Processing",
            },
            {
                value: "delivered",
                label: "Delivered",
            },
            {
                value: "completed",
                label: "Completed",
            },
            {
                value: "shipped",
                label: "Shipped",
            },
            {
                value: "partial-shipped",
                label: "Partial Shipped",
            },
        ];

        const resolveCustomerIsPayingStatus = customerIsPaying => {
            return customerIsPaying ? "Paying Customer" : "Not Paying Customer";
        };

        // Get Order and Start the Process
        // getOrder();
        window.dispatchEvent(new Event("resize"));
        return {
            blankOrderData,
            orderData,
            updateShopId,
            orderInitialCount,
            feeInitialCount,
            getPaymentMethods,
            outFocusActiveField,
            calculateItemTotal,
            triggerActiveField,
            productOptions,
            calculateOrderTotal,
            shopOptions,
            hasShopId,
            getShopsWithQuery,
            orderPaymentOptions,
            getProductsWithQuery,
            itemFormBlankItem,
            hasCustomerOriginId,
            updateCustomerId,
            feeFormBlankItem,
            copyDetails,
            updateItemForm,
            updateFeeForm,
            createOrder,
            resolveItemNames,
            customerOptions,
            activeField,
            selectableModifier,
            setTotal,
            searchProducts,
            searchShops,
            searchCustomers,
            resolveCustomerIsPayingStatus,
            getCustomersWithQuery,
            orderStatusOptions,
        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
    background-color: $product-details-bg;
}

.form-item-action-col {
    width: 27px;
}

.repeater-form {
    // overflow: hidden;
    transition: 0.35s height;
}

.v-select {
    &.item-selector-title,
    &.payment-selector {
        background-color: #fff;

        .dark-layout & {
            background-color: unset;
        }
    }
}

.dark-layout {
    .form-item-section {
        background-color: $theme-dark-body-bg;

        .row .border {
            background-color: $theme-dark-card-bg;
        }
    }
}

.debugger-class-xd {
    outline: 1px solid red;
}

.disabled-form-edit {
    background-color: $input-disabled-bg;
}

.mt-10 {
    margin-top: 0.05rem !important;
    margin-bottom: 0 !important;
}
</style>
