export default {
    order: {
        id: null,
        originId: "",
        status: null,
        originCreateDate: "",
        originUpdateDate: "",
        billing: {
            first_name: "",
            last_name: "",
            company: "",
            address_1: "",
            address_2: "",
            city: "",
            state: "",
            postcode: "",
            country: "",
            email: "",
            phone: "",
        },
        shipping: {
            first_name: "",
            last_name: "",
            company: "",
            address_1: "",
            address_2: "",
            city: "",
            state: "",
            postcode: "",
            country: "",
            phone: "",
        },
        currency: "",
        total: null,
        discount: null,
        fees: [],
        customerOriginId: null,
        paymentMethod: null,
        paymentMethodTitle: "",
        customerIp: "",
        lineItems: [],
        shipment: "",
        source: null,
        createDate: "",
        updatedDate: "",
        shopId: null,
        logoUrl: "",
        shopEditUrl: "",
    },
    messages: [],
    activities: [],
    paymentMethods: [
        {
            id: 1,
            originId: "wunion",
            title: "WU - 10% Discount",
            createDate: "2022-03-25T20:33:37.566Z",
            updatedDate: "2022-03-25T20:33:37.566Z",
            shopId: 1,
        },
        {
            id: 2,
            originId: "alg_custom_gateway_2",
            title: "ZELLE - 10% Discount",
            createDate: "2022-03-25T20:33:37.573Z",
            updatedDate: "2022-03-25T20:33:37.573Z",
            shopId: 1,
        },
        {
            id: 3,
            originId: "alg_custom_gateway_3",
            title: "Chase QuickPay - 10% Discount",
            createDate: "2022-03-25T20:33:37.579Z",
            updatedDate: "2022-03-25T20:33:37.579Z",
            shopId: 1,
        },
        {
            id: 4,
            originId: "alg_custom_gateway_1",
            title: "Credit Card Payment",
            createDate: "2022-03-25T20:33:37.586Z",
            updatedDate: "2022-03-25T20:33:37.586Z",
            shopId: 1,
        },
        {
            id: 5,
            originId: "alg_custom_gateway_4",
            title: "Pay with Crypto - 15% Discount",
            createDate: "2022-03-25T20:33:37.591Z",
            updatedDate: "2022-03-25T20:33:37.591Z",
            shopId: 1,
        },
        {
            id: 6,
            originId: "alg_custom_gateway_5",
            title: "Wire Transfer - 10% Discount",
            createDate: "2022-03-25T20:33:37.596Z",
            updatedDate: "2022-03-25T20:33:37.596Z",
            shopId: 1,
        },
    ],
};
